import React from 'react';
import Nav from './views/nav';
import LandingPage from "./views/landing";
import {
    CircleSvg, goToSection,
    PanelTitle,
    SectionHeading,
    TeamMemberCard
} from "./partials/components";
import logoIcon from './assets/img/logo/srcworx-logo-dark-purple.svg';
import StockImage04 from './assets/img/stock/code-04.svg';
import prototype from './assets/img/stock/prototype-empty.png';
import {ButtonModel, ButtonType, Color} from "./models/viewModels";

function App() {

    let GoToServicesButton = new ButtonModel('OUR SERVICES', ButtonType.Primary, 'src-event_goto');

    return (
        <main id='AppMain'>
            <Nav/>
            <article id='AppBody'>
                <section id={'AppLanding'}>
                    <LandingPage />
                </section>
                <section id={'AppServicesSection'} className={'src-grid-section'}>
                    <div id={'AppServices'}>
                        <div className={'src-grid src-grid-container src-grid-container_services-background'}>
                            <CircleSvg color={Color.MediumGrey} />
                        </div>
                        <div className={'container'}>
                            <SectionHeading title={'OUR SERVICES'} color={Color.DarkPurple}/>
                            <div className={'row'}>
                                <div className={'col-sm-12 col-md-6'}>
                                    <PanelTitle heading='Software Development'
                                                content='Application development for the modern world. Intuitive software tools that empower the way you work.'
                                                button={GoToServicesButton}/>
                                    <PanelTitle heading='Integration and Automation'
                                                content='Improve your business workflow through automation and data integration to help streamline processes and increase productivity.'
                                                button={GoToServicesButton}/>
                                    <PanelTitle heading='Salesforce'
                                                content='Your source for everything Salesforce. We have you covered, from administration to development.'
                                                button={GoToServicesButton}/>
                                </div>
                                <div id={'AppServicesStock04'} className={'col-sm-0 col-md-6'}>
                                    <img className={'src-img_triangle'} src={StockImage04}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id={'AppTeamHeading'} className={'container-fluid'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <SectionHeading title={'WHO WE ARE'} color={Color.DarkPurple}/>
                        </div>
                    </div>
                </section>
                <section id={'AppTeamSection'} className={'src-grid-section'}>
                    <div className={'container-fluid'}>
                        <div className={'row'}>
                            <div id={'AppTeam'} className={'col'}>
                                <div className={'src-grid src-grid-container src-grid-container_team-background'}>
                                    <div className={'src-background_logo'}>
                                        <img className={'src-logo-icon'} src={logoIcon}/>
                                    </div>
                                </div>
                                <div className={'src-grid src-grid-container src-grid-container_team-content'}>
                                    <div id={'TeamMembers'} className={'src-card-wrapper'}>
                                        <TeamMemberCard name={'Tyler Wester'}
                                                        jobTitle={'Owner/Founder'}
                                                        description={'From Germantown, Wisconsin - Tyler is the owner/founder of SRCWORX. ' +
                                                            'He enjoys building enterprise software that solve complex problems, improve efficiencies, and automate business operations.'} />
                                        <TeamMemberCard name={'Calixt Charlebois'}
                                                        jobTitle={'Software Engineer'}
                                                        description={'Born and raised in Escanaba, Michigan - Calixt is currently attending Milwaukee School of Engineering (MSOE) where he is majoring in Software Engineering. ' +
                                                            'Calixt has extensive knowledge of computer systems, embedded programming, and statistical analysis.'} />
                                        <TeamMemberCard name={'Rudy Fisher'}
                                                        jobTitle={'Software Engineer'}
                                                        description={'Living in Neenah, Wisconsin - Rudy is a recent graduate of The University of Oshkosh where he earned his Bachlor\'s Degree in Computer Science. Rudy is highly skilled with building and programming embedded systems, power electronics, and web applications.'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id={'AppProjectSection'} className={'src-grid-section'}>
                    <div className={'container-fluid mock-panel'}>
                        <div className={'row'}>
                            <div id={'AppProject'} className={'col'}>
                                <div className={'src-grid src-grid-container src-grid-container_project-background'}>
                                </div>
                                <div className={'src-grid src-grid-container src-grid-container_project-content'}>
                                    <div className={'src-card-wrapper'}>
                                        <SectionHeading title={'OUR WORK'} color={Color.DarkPurple}/>
                                        <div id={'ProjectDisplay'}>
                                            <img src={prototype}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article>
        </main>
    );
}

export default App;
