import React from 'react';
import { render } from "react-dom";
import App from './App';
import $ from 'jquery';
import {EventHub} from "./common/events";
import {goToSection} from "./partials/components";

// initialize event hub
const eventHub = new EventHub();

// render view
let appRenderTarget = document.getElementById("App");
render(<App />, appRenderTarget);

// register events
const pageScrollHandler = function(context: any) {
    let navBar = $('#AppNav');
    if (context.hasOwnProperty('pos') && navBar != undefined) {
        context.pos > 80
            ? navBar.addClass('sticky')
            : navBar.removeClass('sticky');
    }
};

eventHub.subscribe('page scroll', pageScrollHandler);

// register page scroll event
$(function() {
    $('.src-event_goto').on('click', function() {
        let sender = $(this);
        let goToElementId = sender.data('element-id') ?? 'AppServicesSection';
        let element = $('#' + goToElementId);
        let mobileNav = $('#MobileMenu');

        if (mobileNav.hasClass('opened')) {
            $('#MobileNavButton').click();
        }

        // @ts-ignore
        let offset = Math.round(element.offset().top);
        let scrollPos = window.scrollY;
        console.log(offset, element.position(), window.scrollY);

        if (offset != scrollPos) {
            $('html, body').animate({
                scrollTop: offset
            }, 0);
        }
    });

    $(document).on('scroll', function() {
        eventHub.publish('page scroll', {message: 'page scrolled', pos: window.scrollY});
    });

    $('#MobileNavButton').on('click',function () {
        if ($('#MobileMenu').hasClass('opened')) {
            $(this).removeClass('opened');
            $('#MobileMenu').addClass('d-none');
            $('#MobileMenu').removeClass('opened');
        } else {
            $(this).addClass('opened');
            $('#MobileMenu').removeClass('d-none');
            $('#MobileMenu').addClass('opened');
        }
    });
});