import React from 'react';
import {Button, CardSingleButton, goToSection} from '../partials/components'
import introImage from '../assets/img/stock/workstation.svg';
import {ButtonModel, ButtonType, CardType} from "../models/viewModels";

function App() {

    let GoToServicesButton = new ButtonModel('OUR SERVICES', ButtonType.Primary, 'src-event_goto');

    return (
        <div id={'AppLandingRoot'}>
            <div id={'AppLandingContent'} className={'container-fluid'}>
                <div className={'row h-100'}>
                    <div id={'AppLandingContentContainer'} className={'src-landing-content col-sm-12 col-md-6'}>
                        <div className='d-flex flex-column'>
                            <div className={'src-landing-heading-container'}>
                                <h1 className={'display-3 src-landing-heading text-dark-purple'}>Software Solutions.</h1>
                                <h1 className={'display-3 src-landing-heading text-dark-grey'}>Simplified.</h1>
                            </div>
                            <div className={'src-heading-3 d-flex flex-row'}>
                                <span className={'src-heading-lead'}></span>
                                <h5>What We Do</h5>
                            </div>
                            <p className='src-text text-dark-grey'>
                                We build customized software solutions to achieve your goals and empower your business.
                                Upgrade your technology and explore new possibilities.
                            </p>
                            <div className={'src-landing-button-group'}>
                                <Button text={'Learn More'} type={ButtonType.Secondary} className={'src-button_goto'}/>
                            </div>
                        </div>
                    </div>
                    <div id='LandingImageContainer' className={'src-landing-content col-sm-0 col-md-6'}>
                        <img src={introImage} />
                    </div>
                </div>
            </div>
            <div id={'AppAboutSection'}>
                <div id={'AppAboutSectionContainer'} className={'container-fluid'}>
                    <div className={'src-card_spaced row'}>
                        <div className={'src-card_about col-sm-12 col-md-4'}>
                            <CardSingleButton
                                title={'Personalized Websites'}
                                description={'Make your website as unique as you are with a custom website design, created to suit your business and engage users. ' +
                                    'Bring your ideas to reality and elevate your business.'}
                                cardType={CardType.Light}
                                buttonModelFirst={GoToServicesButton}
                            />
                        </div>
                        <div className={'src-card_about col-sm-12 col-md-4'}>
                            <CardSingleButton
                                title={'Application Development'}
                                description={'Customize your user experience ' +
                                    'and improve your businesses efficiency by creating a software solution that is tailored to the way you work.'}
                                cardType={CardType.Light}
                                buttonModelFirst={GoToServicesButton}
                            />
                        </div>
                        <div className={'src-card_about col-sm-12 col-md-4'}>
                            <CardSingleButton
                                title={'Integration and Automation'}
                                description={'Bridge the gap between different software solutions and harmonize your company\'s data ' +
                                    'with a specialized integration and automation.' }
                                cardType={CardType.Light}
                                buttonModelFirst={GoToServicesButton}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
