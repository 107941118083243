import React from 'react';
import '../assets/css/style.scss';
import {Button, goToSection} from "../partials/components";
import {Logo} from '../partials/logo';
import {ButtonType} from "../models/viewModels";
import {MobileNavButton} from "../partials/navMobileButton";

function Nav() {
    return (
        <div id='AppNav'>
            <nav className="navbar navbar-expand-md navbar-light justify-content-between">
                <a className="navbar-brand p-0" href="#">
                    <div className={'navbar-brand-logo'}>
                        <Logo/>
                    </div>
                </a>
                <div className="collapse navbar-collapse justify-content-center">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link text-dark-grey src-event_goto" href="#" data-element-id='AppTeamHeading'>About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-dark-grey src-event_goto" href="#" data-element-id='AppServicesSection'>Services</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-dark-grey src-event_goto" href="#" data-element-id='AppProjectSection'>Our Work</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-dark-grey src-event_goto" href="#" data-element-id='AppTeamHeading'>The Team</a>
                        </li>
                    </ul>
                </div>
                <div id={'AppNavButtonGroup'}>
                    <MobileNavButton/>
                    <div className='btn-group'>
                        <Button text={'Contact'} type={ButtonType.Success} className={'src-event_goto'}/>
                    </div>
                </div>
            </nav>
            <div id="MobileMenu" className="d-none">
                <div className={'d-flex flex-column h-100'}>
                    <div className="nav-logo-mobile">
                        <Logo/>
                    </div>
                    <div className="menu-items d-flex flex-column justify-content-between h-100">
                        <div className="d-flex flex-column">
                            <a className="nav-link text-dark-grey src-event_goto" href="#" data-element-id='AppTeamHeading'>About</a>
                            <a className="nav-link text-dark-grey src-event_goto" href="#" data-element-id='AppServicesSection'>Services</a>
                            <a className="nav-link text-dark-grey src-event_goto" href="#" data-element-id='AppProjectSection'>Our Work</a>
                            <a className="nav-link text-dark-grey src-event_goto" href="#" data-element-id='AppTeamHeading'>The Team</a>
                            <a className="nav-link text-dark-grey src-event_goto" href="#">Contact</a>
                        </div>
                    </div>
                    <div className="mobile-menu-footer">
                        <div className={'mobile-menu-footer-logo'}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Nav;