export interface CardModel {
    title: string;
    description: string;
    cardType: CardType;
}

export interface CardSingleButtonModel extends CardModel {
    buttonModelFirst: ButtonModel
}

export interface CardDoubleButtonModel extends CardModel {
    buttonModelFirst: ButtonModel,
    buttonModelSecond: ButtonModel
}

export interface SectionHeadingModel {
    title: string;
    color: Color;
}

export class ButtonModel {
    text: string;
    type: ButtonType;
    className: string

    constructor(text: string, type: ButtonType, className: string) {
        this.text = text;
        this.type = type;
        this.className = className;
    }
}

export class PanelModel {
    heading: string;
    content: string;
    button: ButtonModel;

    constructor(heading: string, content: string, button: ButtonModel) {
        this.heading = heading;
        this.content = content;
        this.button = button;
    }
}

export interface Circle {
    color: Color;
}

export class TeamMember {
    name: string;
    jobTitle: string;
    description: string;

    constructor(name: string, jobTitle: string, description: string) {
        this.name = name;
        this.jobTitle = jobTitle;
        this.description = description;
    }
}

export enum CardType {
    White = 0,
    Light = 1,
    Dark = 2
}

export enum ButtonType {
    Primary = 0,
    Secondary = 1,
    Success = 2,
    Default = 3,
    Outline = 4,
    OutlineGreen = 6,
    Purple = 5
}

export enum Color {
    Purple = 0,
    Blue = 1,
    Red = 2,
    Yellow = 3,
    Grey = 4,
    MediumGrey = 5,
    DarkPurple = 6
}