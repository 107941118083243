import _ from 'lodash';

export class EventTopic {
    name: string;
    subscribers: Array<Function> = new Array<Function>();

    constructor(name: string) {
        this.name = name;
        this.subscribers = new Array<Function>();
    }
}

export class EventHub {
    topics: Array<EventTopic> = new Array<EventTopic>();

    private getTopic(topic:string) {
        return _.find(this.topics, function (s) {
            return s.name == topic;
        });
    }

    public subscribe(topicName: string, handler: Function) {
        let topicExists = this.getTopic(topicName) != undefined;
        if (!topicExists)
        {
            let newTopic = new EventTopic(topicName);
            this.topics.push(newTopic);
        }

        let topic = this.getTopic(topicName);
        topic?.subscribers.push(handler);
    }

    public publish(topicName: string, context: object) {
        // only publish if there are observers
        let topic = this.getTopic(topicName);
        let topicExists = topic != undefined;

        if(!topicExists) return;
        if(topic?.subscribers.length == 0) return;

        topic?.subscribers.forEach(function(handler) {
            handler(context ?? {});
        })
    }
};
