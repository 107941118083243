import React from 'react';
import '../assets/css/style.scss';
import check from '../assets/icons/checkmark_light-green.png';
import TeamMemberTyler from '../assets/img/headshot/tyler-headshot-round.png';
import TeamMemberCalixt from '../assets/img/headshot/calixt-headshot.png';
import TeamMemberRudy from '../assets/img/headshot/rudy-headshot.png';
import {
    ButtonModel,
    ButtonType,
    CardDoubleButtonModel,
    CardSingleButtonModel,
    CardType,
    Circle,
    Color,
    PanelModel,
    SectionHeadingModel,
    TeamMember
} from "../models/viewModels";

export function PanelTitle(model:PanelModel) {
    return (
        <div className='src-panel-wrapper'>
            <div className='src-panel-content'>
                <div className='src-panel-heading'>
                    <img src={check}/>
                    <h2>{model.heading}</h2>
                </div>
                <div className='src-panel-card'>
                    <p>{model.content}</p>
                    <div className='src-button-group d-flex flex-row justify-content-end'>
                        <Button text={model.button.text} type={model.button.type} className={'src-event_goto'}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function TeamMemberCard(teamMember:TeamMember) {
    let classStyle = getCardClassName(CardType.Light);
    let teamMemberPhoto;
    switch (teamMember.name) {
        case 'Tyler Wester':
            teamMemberPhoto = <img src={TeamMemberTyler} alt='Team Member' />
            break;
        case 'Calixt Charlebois':
            teamMemberPhoto = <img src={TeamMemberCalixt} alt='Team Member' />
            break;
        case 'Rudy Fisher':
            teamMemberPhoto = <img src={TeamMemberRudy} alt='Team Member' />
            break;
        default:
            teamMemberPhoto = <img src='' alt='Team Member' />
            break;
    }

    return (
        <div className='src-card-team'>
            <div className='d-flex flex-row mb-3'>
                <div className='team-member-photo'>
                    {teamMemberPhoto}
                </div>
                <div className='team-member-heading'>
                    <h2 className='text-white'>{teamMember.name}</h2>
                    <h3 className='text-white'>{teamMember.jobTitle}</h3>
                </div>
            </div>
            <div className={classStyle}>
                <p className='text-dark-grey'>{teamMember.description}</p>
            </div>
        </div>
    );
}

export function CardSingleButton(model:CardSingleButtonModel) {
    let classStyle = getCardClassName(model.cardType);
    return (
        <div className={classStyle}>
            <div className={'src-card-content'}>
                <h1 className='text-dark-purple'>{model.title}</h1>
                <p className='src-text text-dark-grey'>{model.description}</p>
            </div>
            <div className='src-button-group d-flex flex-row'>
                <Button text={model.buttonModelFirst.text} type={model.buttonModelFirst.type} className={'src-event_goto'}/>
            </div>
        </div>
    );
}

export function CardDoubleButton(model:CardDoubleButtonModel) {
    let classStyle = getCardClassName(model.cardType);
    return (
        <div className={classStyle}>
            <h1 className='text-purple'>{model.title}</h1>
            <p className='src-text text-dark-grey'>{model.description}</p>
            <div className='src-button-group d-flex flex-row'>
                <Button text={model.buttonModelFirst.text} type={model.buttonModelFirst.type} className={'src-event_goto'}/>
                <Button text={model.buttonModelSecond.text} type={model.buttonModelSecond.type} className={'src-event_goto'}/>
            </div>
        </div>
    );
}

export function SectionHeading(model:SectionHeadingModel) {
    let classStyle = 'src-section-heading';
    switch (model.color) {
        case Color.Purple:
            classStyle += ' ' + 'text-purple';
            break;
        case Color.DarkPurple:
            classStyle += ' ' + 'text-dark-purple';
            break;
        case Color.Grey:
            classStyle += ' ' + 'text-light-grey';
            break;
        case Color.MediumGrey:
            classStyle += ' ' + 'text-medium-grey';
            break;
        default:
            classStyle += ' ' + 'text-light-grey';
    }

    return (
        <div className={'src-heading'}>
            <h1 className={classStyle}>{model.title}</h1>
            <hr className={'src-divider'}/>
        </div>
    );
}

export function CircleSvg(model:Circle) {
    let classStyle = 'src-background src-background_circle';

    switch (model.color) {
        case Color.Purple:
            classStyle += ' ' + 'src-circle_purple';
            break;
        case Color.DarkPurple:
            classStyle += ' ' + 'src-circle_dark-purple';
            break;
        case Color.Grey:
            classStyle += ' ' + 'src-circle_grey';
            break;
        case Color.MediumGrey:
            classStyle += ' ' + 'src-circle_medium-grey';
            break;
        default:
            classStyle += ' ' + 'src-circle_grey';
    }

    return (
        <div className={classStyle}>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" />
            </svg>
        </div>
    );
}

export function Button(model:ButtonModel) {
    let style = 'src-button ' + model.className;
    switch (model.type) {
        case ButtonType.Primary:
            style += ' ' + 'src-button_primary';
            break;
        case ButtonType.Secondary:
            style += ' ' + 'src-button_secondary';
            break;
        case ButtonType.Success:
            style += ' ' + 'src-button_success';
            break;
        case ButtonType.Default:
            style += ' ' + 'src-button_default';
            break;
        case ButtonType.Outline:
            style += ' ' + 'src-button_outline';
            break;
        case ButtonType.OutlineGreen:
            style += ' ' + 'src-button_outline-green';
            break;
        case ButtonType.Purple:
            style += ' ' + 'src-button_purple';
            break;
        default:
            style += ' ' + 'src-button_default';
    }

    return (
        <button className={style}>{model.text}</button>
    );
}

function getCardClassName(model:CardType) {
    let styleName = 'src-card';
    switch (model) {
        case CardType.White:
            styleName += ' ' + 'src-card_white';
            break;
        case CardType.Light:
            styleName += ' ' + 'src-card_light';
            break;
        case CardType.Dark:
            styleName += ' ' + 'src-card_dark';
            break;
        default:
            styleName += ' ' + 'src-card_white';
    }

    return styleName;
}

export function goToSection(elementId:string) {
    let el = document.getElementById(elementId);
    if (el) {
        let elementTop = el.offsetTop
        window.scroll({
            top: elementTop,
            behavior: 'auto'
        })
    }
}