import React from 'react';
import '../assets/css/style.scss';

export function Logo() {
    return (
        <svg id="LogoSvg" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" className='src-logo_svg' viewBox="0 0 600 115">
            <g id="icon">
                <g transform="matrix(-0.594868,-0.803824,-0.803824,0.594868,98.4292,74.7119)">
                    <path
                        d="M-9.293,-18.441C-15.679,-18.441 -20.855,-13.264 -20.856,-6.879C-20.856,-0.493 -15.679,4.684 -9.293,4.684C-2.908,4.684 2.269,-0.492 2.269,-6.878C2.269,-13.264 -2.907,-18.44 -9.293,-18.441" />
                </g>
                <g transform="matrix(-0.803815,0.59488,0.59488,0.803815,70.2281,57.2133)">
                    <path d="M-26.037,44.063L-13.664,44.063L-16.163,-10.791L-23.926,-10.791L-26.037,44.063Z"/>
                </g>
                <g transform="matrix(0.594906,-0.803795,-0.803795,-0.594906,87.476,31.4921)">
                    <path
                        d="M-7.744,-3.903C-2.423,-3.902 1.89,0.411 1.89,5.732C1.89,11.053 -2.423,15.366 -7.744,15.366C-13.065,15.365 -17.378,11.052 -17.378,5.731C-17.378,0.41 -13.065,-3.903 -7.744,-3.903"/>
                </g>
                <g transform="matrix(-0.80379,-0.594913,-0.594913,0.80379,46.9869,50.3434)">
                    <path d="M-20.475,-31.309L-10.196,-31.309L-11.876,8.847L-18.108,8.601L-20.475,-31.309Z"/>
                </g>
                <g transform="matrix(-0.594893,-0.803805,-0.803805,0.594893,43.3454,68.2487)">
                    <path
                        d="M-6.122,-12.147C-10.328,-12.147 -13.738,-8.738 -13.738,-4.532C-13.738,-0.325 -10.328,3.084 -6.122,3.084C-1.915,3.085 1.494,-0.326 1.494,-4.531C1.494,-8.738 -1.916,-12.148 -6.122,-12.147"/>
                </g>
                <g transform="matrix(-0.256251,-0.96661,-0.96661,0.256251,32.8261,45.5751)">
                    <path
                        d="M-4.519,-5.872C-7.102,-5.873 -9.195,-3.779 -9.195,-1.197C-9.194,1.385 -7.101,3.478 -4.519,3.478C-1.937,3.478 0.156,1.385 0.156,-1.197C0.156,-3.78 -1.937,-5.873 -4.519,-5.872"/>
                </g>
                <g transform="matrix(-0.803793,0.594909,0.594909,0.803793,30.1327,60.5009)">
                    <path d="M-14.084,20.802L-6.491,20.802L-7.901,-6.051L-12.886,-5.805L-14.084,20.802Z"/>
                </g>
            </g>
            <g id="text">
                <g transform="matrix(1,0,0,1,142.904,36.7828)">
                    <path
                        d="M0,41.435L3.322,36.238C7.99,40.011 13.606,41.507 19.381,41.507C27.766,41.507 32.909,38.445 32.909,33.532C32.909,28.335 27.213,26.058 19.065,23.921L15.426,22.996C6.565,20.789 -0.554,17.301 -0.554,8.828C-0.554,-0.285 7.752,-6.336 20.251,-6.336C27.925,-6.336 34.174,-4.272 39.158,-0.285L35.361,4.983C31.168,1.637 26.343,-0.071 20.251,-0.071C12.973,-0.071 8.227,3.061 8.227,7.689C8.227,12.744 13.369,14.595 19.381,16.161L23.178,17.086C31.88,19.365 41.689,23.352 41.689,32.821C41.689,41.791 32.909,47.771 19.855,47.771C12.103,47.771 4.825,45.707 0,41.435"/>
                </g>
                <g transform="matrix(1,0,0,1,205.077,57.9277)">
                    <path
                        d="M0,-0.854L13.052,-0.854C20.726,-0.854 25.788,-4.77 25.788,-10.68C25.788,-16.589 20.884,-20.505 13.527,-20.505L0,-20.505L0,-0.854ZM28.953,25.915L14.397,5.41L0,5.41L0,25.915L-8.465,25.915L-8.465,-26.769L14.555,-26.769C26.58,-26.769 34.57,-20.362 34.57,-10.68C34.57,-3.631 30.139,1.709 22.861,4.058L38.841,25.915L28.953,25.915Z"/>
                </g>
                <g transform="matrix(1,0,0,1,249.372,57.6419)">
                    <path
                        d="M0,-0.284C0,-16.446 12.104,-27.196 30.22,-27.196C39.791,-27.196 47.227,-24.134 52.765,-18.653L47.464,-13.811C43.747,-17.94 37.972,-20.93 30.457,-20.93C17.404,-20.93 8.782,-12.671 8.782,-0.284C8.782,12.246 17.483,20.648 30.377,20.648C38.762,20.648 44.854,17.158 48.81,11.819L54.584,16.518C49.126,23.068 40.741,26.912 30.298,26.912C12.183,26.912 0,16.019 0,-0.284"/>
                </g>
                <g transform="matrix(1,0,0,1,394.607,83.8427)">
                    <path
                        d="M0,-52.684L-20.567,0L-28.479,0L-43.825,-41.863L-59.172,0L-67.004,0L-87.65,-52.684L-78.632,-52.684L-62.653,-10.182L-47.781,-52.684L-39.869,-52.684L-24.84,-10.182L-9.019,-52.684L0,-52.684Z"/>
                </g>
                <g transform="matrix(1,0,0,1,449.579,57.4995)">
                    <path
                        d="M0,0.001C0,-12.458 -8.543,-20.788 -21.201,-20.788C-33.937,-20.788 -42.4,-12.458 -42.4,0.001C-42.4,12.46 -33.937,20.79 -21.201,20.79C-8.543,20.79 0,12.46 0,0.001M-51.182,0.001C-51.182,-16.231 -39.157,-27.053 -21.201,-27.053C-3.322,-27.053 8.702,-16.231 8.702,0.001C8.702,16.233 -3.322,27.054 -21.201,27.054C-39.157,27.054 -51.182,16.233 -51.182,0.001"/>
                </g>
                <g transform="matrix(1,0,0,1,478.843,57.9277)">
                    <path
                        d="M0,-0.854L13.052,-0.854C20.726,-0.854 25.788,-4.77 25.788,-10.68C25.788,-16.589 20.884,-20.505 13.527,-20.505L0,-20.505L0,-0.854ZM28.953,25.915L14.397,5.41L0,5.41L0,25.915L-8.464,25.915L-8.464,-26.769L14.556,-26.769C26.58,-26.769 34.569,-20.362 34.569,-10.68C34.569,-3.631 30.14,1.709 22.861,4.058L38.842,25.915L28.953,25.915Z"/>
                </g>
                <g transform="matrix(1,0,0,1,562.691,31.1588)">
                    <path
                        d="M0,52.684L-16.375,31.183L-32.83,52.684L-42.56,52.684L-21.122,25.487L-40.898,0L-31.168,0L-16.455,19.579L-1.74,0L7.989,0L-11.708,25.416L9.729,52.684L0,52.684Z"/>
                </g>
            </g>
        </svg>
    )
};
